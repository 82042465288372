<template>
    <div>
        <van-cell-group>
            <van-field label="姓名" :value="info.name" readonly />
            <van-field label="身份证号" :value="info.card" readonly />
            <van-field label="申请项目" :value="info.entry" readonly />
            <van-field label="具体项目" v-if="info.entry_type" :value="info.entry_type" readonly />
        </van-cell-group>
        <van-cell-group>
            <van-field label="身份证正面" name="uploader">
                <template #input>
                    <van-image width="100px" height="100px" fit="contain" :src="images[0]" @click="look(0)" />
                </template>
            </van-field>
            <van-field name="uploader" label="身份证反面">
                <template #input>
                    <van-image width="100px" height="100px" fit="contain" :src="images[1]" @click="look(1)" />
                </template>
            </van-field>
            <van-field name="uploader" label="头像">
                <template #input>
                    <van-image width="100px" height="100px" fit="contain" :src="images[2]" @click="look(2)" />
                </template>
            </van-field>
            <van-field name="uploader" label="生成证书" v-if="info.state == 1">
                <template #input>
                    <van-image width="100px" height="100px" fit="contain" :src="images[3]" @click="look(3)" />
                </template>
            </van-field>

            <van-field label="审批意见" v-if="info.state == 1 || info.state == 2" :value="info.state == 1 ? '通过' : '拒绝'"
                readonly />
            <van-field label="审批意见" v-if="info.state == 0">
                <template #input>
                    <van-radio-group v-model="state" direction="horizontal">
                        <van-radio :name="1">通过</van-radio>
                        <van-radio :name="2">拒绝</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <van-field v-if="state == 2 || info.state == 2" :readonly="info.state ? true : false" v-model="reason" rows="2"
                autosize label="拒绝理由" type="textarea" maxlength="50" placeholder="请输入拒绝理由" show-word-limit />
        </van-cell-group>

        <div style="margin: 16px;" v-if="info.state == 0">
            <van-button round block type="info" :loading="loading" @click="post">提交</van-button>
        </div>
    </div>
</template>

<script>
import { ImagePreview, Toast } from 'vant';
export default {
    data() {
        return {
            loading: false,
            info: { state: 0 },
            state: 0,
            reason: '',
            images: ['', '', '', ''],
        }
    },
    components: {
        [ImagePreview.Component.name]: ImagePreview.Component,
        [Toast.name]: Toast, // 文字提示
    },
    mounted() {
        this.get()
    },
    methods: {
        look(e) {
            ImagePreview({
                images: this.images,
                startPosition: e,
                closeable: true,
            });
        },

        get() {
            this.loading = true;
            this.$request(7, { id: this.$route.query.id, openid: this.$localStorage.get('openid'), type: 'examine' })
                .then((res) => {
                    this.info = res;
                    let arr = [
                        res.front,
                        res.opposite,
                        res.head,
                    ];
                    if (res.adds) { arr.push(res.adds); }
                    this.images = arr;
                    this.reason = res.reason;
                    this.loading = false;
                })
                .catch((message) => {
                    Toast(message);
                    this.$set(this.info, 'state', 3)
                    this.loading = false;
                })
        },

        post() {
            let { state, reason } = this;
            if (!state) {
                Toast({
                    message: `请选择审批意见`,
                    position: 'top'
                })
                return false;
            }
            if (state == 2 && !reason.trim()) {
                Toast({
                    message: `请输入拒绝理由`,
                    position: 'top'
                })
                return false;
            }
            let _this = this;
            this.loading = true;
            this.$request(8, { id: this.$route.query.id, state, reason: state == 1 ? '' : reason.trim() })
                .then((res) => {
                    this.loading = false;
                    Toast('审批成功！');
                    setTimeout(() => {
                        if (state == 2) {
                            _this.get();
                        } else {
                            _this.generate();
                        }
                    }, 1000)
                })
                .catch((message) => {
                    _this.loading = false;
                    Toast(message);
                })

        },

        generate() {
            this.$request(9, { id: this.$route.query.id })
                .then(() => { this.get() })
                .catch(() => { })
        }
    },
}

</script>
<style scoped lang='less'></style>